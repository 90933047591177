import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql, navigate } from "gatsby"
import "../../styles/cloud-networking.less"
import SEO from "../../components/SEO"
import { ProductNav } from "../../components/header/Header"
import {
  ProductSupport,
  SolutionTopBanner,
  ProductAction,
  ProductBlog,
  AnimationWrap,
} from "../../components/block"
import { Action } from "./edge-data-center-services"
// import CloudNetworkingTable from "../../components/table/CloudNetworkingTable"
// import { lang } from "../../utils"
// import { isMobile } from "../../utils"
// const CloudNetworkingTable = require(`../../assets/cloud-wan-${lang}.svg`)
import { MapContainer, Feedback, Solution } from "./bare-metal-cloud"
const staticPath = "/products/cloud-networking"
const loadText = require("src/utils").loadText
const static_data = loadText("cloud-networking")
const url_data = loadText("url")
import { lang } from "../../utils"
const CloudNetworkingTable = require(`../../assets/cloud-networking-table-${lang}.svg`)
const CloudNetworkingTableMobile = require(`../../assets/cloud-networking-table-m-${lang}.svg`)
const B1 = () => (
  <div className="cloudNetworking-b1">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color mobile-padding">
        {static_data.b1.title}
      </div>
      <div
        className="mt-16 font-20 block-middle mobile-padding"
        style={{ maxWidth: "1016px" }}
        dangerouslySetInnerHTML={{ __html: static_data.b1.description }}
      />
    </AnimationWrap>
  </div>
)
const B2 = () => (
  <div className="cloudNetworking-b2">
    <AnimationWrap>
      <div className="circle" />
      <div className="cloudNetworking-b2-content">
        <div className="left">
          <div className="label">{static_data.subject.cloud}</div>
          <div className="pc-partner">
            {(lang === "en"
              ? ["aws", "google", "azure", "ucloud", "aliyun", "qcloud"]
              : ["aws", "azure", "ucloud", "aliyun", "qcloud"]
            ).map(name => (
              <img
                src={`${staticPath}/${name}.svg`}
                alt={name}
                className="cloudNetworking-b2-icon"
              />
            ))}
          </div>
          {/* <div className="label mt-16">{static_data.subject.interconnect}</div>
          <div className="ci-partner">
            {(lang === "en" ? ["megaport", "coresite", "equinix"] : []).map(
              name => (
                <img
                  src={`${staticPath}/${name}.svg`}
                  alt={name}
                  className="cloudNetworking-b2-icon"
                />
              )
            )}
          </div> */}
        </div>
        <div className="right mt-40">
          <div className="font-36 semi-bold t-color">
            {static_data.b2.title}
          </div>
          <div className="description mt-24 font-20">
            {static_data.b2.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B2a = () => (
  <div className="cloudNetworking-b2a">
    <AnimationWrap>
      <div className="cloudNetworking-b2a-content">
        <div className="left mt-64">
          <div className="font-36 semi-bold t-color">
            {static_data.b2a.title}
          </div>
          <div className="description font-20 mt-24">
            {static_data.b2a.description}
          </div>
        </div>
        <div className="right">
          {/* <img src={`${staticPath}/b2a-pic.png`} alt="" /> */}
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B2b = () => (
  <div className="cloudNetworking-b2b">
    <AnimationWrap>
      <div className="cloudNetworking-b2b-content">
        <div className="left">
          <div className="font-36 semi-bold">{static_data.b2b.title}</div>
          <div className="description font-20 mt-24">
            {static_data.b2b.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B3 = () => (
  <div className="cloudNetworking-b3">
    <AnimationWrap>
      <div className="cloudNetworking-b3-content">
        <div className="cloudNetworking-b3-imgContainer">
          <img src={`${staticPath}/b3-pic.png`} alt="" />
        </div>
        <div className="right mt-24">
          <div className="font-36 semi-bold t-color">
            {static_data.b3.title}
          </div>
          <div className="description font-20 mt-24">
            {static_data.b3.description}
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const Hybrid = () => (
  <div className="cloudNetworking-hybrid">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color mobile-padding">
        {static_data.hybrid.title}
      </div>
      <div className="page-middle">
        {static_data.hybrid.content.map((c, i) => (
          <div className="cloudNetworking-hybrid-item">
            <img src={`${staticPath}/hybrid-${i + 1}.svg`} alt={c} />
            <div className="font-20">{c}</div>
          </div>
        ))}
      </div>
    </AnimationWrap>
  </div>
)
const FitYourNeed = () => (
  <div className="cloudNetworking-fitYourNeed">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color mobile-padding">
        {static_data.fitYourNeed.title}
      </div>
      <div className="page-middle">
        {static_data.fitYourNeed.content.map(({ name, description }, i) => (
          <div className="cloudNetworking-fitYourNeed-item">
            <div className="pic-container">
              <img src={`${staticPath}/need-${i + 1}.svg`} alt={name} />
            </div>
            <div className="font-28 semi-bold t-color">{name}</div>
            <div className="font-20 mt-8">{description}</div>
          </div>
        ))}
      </div>
    </AnimationWrap>
  </div>
)
export const Benefits = () => (
  <div className="cloudNetworking-benefits">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color mobile-padding">
        {static_data.benefits.title}
      </div>
      <div className="description mt-24 font-20 mobile-padding">
        {static_data.benefits.description}
      </div>
      <div className="mt-48 block-middle" style={{ maxWidth: "1224px" }}>
        <div className="only-desktop">
          <CloudNetworkingTable />
        </div>
        <div className="only-mobile">
          <img
            src={`/cloud-networking-table-m-${lang}.svg`}
            alt="cloud-table"
            style={{ padding: "0 48px" }}
          />
          {/* <CloudNetworkingTableMobile /> */}
        </div>
        {/* <img src={`${staticPath}/benefits-table.svg`} alt="benefits-table" /> */}
      </div>
    </AnimationWrap>
  </div>
)
const Feature = () => {
  // const [active, setActive] = useState(1)
  return (
    <div className="cloudNetworking-feature">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color mobile-padding">
          {static_data.features.title}
        </div>
        <div
          className="mt-24 font-20 mobile-padding"
          dangerouslySetInnerHTML={{ __html: static_data.features.description }}
        />
        {/* <div className="only-desktop">
        <div className="left">
          {static_data.features.content.map(({ name }, idx) => {
            const isActive = idx + 1 === active
            return (
              <div>
                <div
                  className={`font-20 mt-24 left-item ${
                    isActive ? "active" : ""
                  }`}
                  onMouseEnter={() => {
                    setActive(idx + 1)
                  }}
                >
                  {name}
                </div>
              </div>
            )
          })}
        </div>
        <div
          className="right font-20"
          style={{
            backgroundImage: `url("${staticPath}/feature-${active}.svg")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="content">
            <div className="semi-bold t-color">
              {static_data.features.content[active - 1].name}
            </div>
            <div className="mt-24" style={{ maxWidth: "352px" }}>
              {static_data.features.content[active - 1].description}
            </div>
          </div>
        </div>
      </div> */}
        <div style={{ maxWidth: 1240, margin: "0 auto" }}>
          <ul className="cloudNetworking-feature-cardwrap">
            {static_data.features.content.map((item, idx) => {
              const img = `${staticPath}/feature-${idx + 1}.svg`
              return (
                <li
                  key={idx}
                  className="cloudNetworking-feature-card"
                  style={{
                    backgroundImage: `url("${img}")`,
                  }}
                >
                  <img src={img} alt="" className="only-desktop" />
                  <div className="name mt-24 font-20 semi-bold t-color">
                    {item.name}
                  </div>
                  <div className="description mt-8 font-16 t-color">
                    {item.description}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </AnimationWrap>
    </div>
  )
}
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="cloudNetworking">
      <SEO {...static_data.seo} featuredImage="/featuredImage/sdn.jpg" />
      <a id="overview" />
      <SolutionTopBanner
        className="sdn-banner"
        source="sdn"
        backgroundAnimated={true}
        animatedIcon={`${staticPath}/animatedIcon.svg`}
        {...static_data.topBanner}
        bgColor="linear-gradient(180deg,#F2FDF7,#E0F9EC)"
        iconBg={`${staticPath}/iconBg.svg`}
        bgPic={`${staticPath}/topBanner-bg.svg`}
        bannerPic={{
          src: `${staticPath}/topBanner-m-bg.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
      />
      {/* <ProductNav
        name={static_data.cloudNetworking}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="keyFeatures" />
      <B1 />
      <B2 />
      <B2a />
      <B2b />
      <B3 />
      <ProductSupport
        source="sdn"
        bgColor="linear-gradient(#F2FDF7, #E0F9EC)"
        bgPic={`${staticPath}/support-bg.svg`}
        woman={`${staticPath}/support-woman.png`}
      />
      <CloudNetworkingTable />
      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.sdn.name]}
      />
      <Hybrid />
      <FitYourNeed />
      <a id="benefits" />
      <Benefits />
      <Feature />
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <Action
        pic={`${staticPath}/action.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          navigate("/customer-story/digital-media")
        }}
      />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      /> */}
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Cloud Networking" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          categories {
            name
          }
          created_at
          content
          title
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "sdn" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
